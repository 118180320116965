import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { EversoulCharacter } from '../../../modules/es/common/components/eversoul-character';

const EsGuidesCharite: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Charite - raid guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_charite.jpg"
            alt="Charite - raid guide"
          />
        </div>
        <div className="page-details">
          <h1>Charite - raid guide</h1>
          <h2>Struggling with the Charite Raid? This guide will help you!</h2>
          <p>
            Last updated: <strong>13/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Charite Mechanics" />
        <p>
          Charite is pretty much the most straightforward guild raid boss we’ve
          gotten (and since it’s the first that probably makes sense). The most
          important mechanic to work around in this raid is the mana absorption
          - bringing characters that provide shields is imperative to doing well
          in this raid.
        </p>
        <p>
          Charite’s skill rotation is short as she does not use her main skill
          unless her mana bar fills. Using her main skill does not break her
          rotation, so whenever her mana bar fills she will main skill, then
          continue on the rotation where she left off.
        </p>
        <p>Normal &gt; Charge &gt; Normal &gt; Bombardment &gt; Absorption</p>
        <p>
          The normal attacks and Charge don’t do anything special, though later
          in the fight when Charite has attack stacks, charge does huge amounts
          of damage. Bombardment puts an attack debuff on your whole team, which
          can be cleansed or waited out. Absorption is the big ticket mechanic
          to watch out for and will be explained in detail in this guide.
        </p>
        <h5>Bombardment (Sky lasers)</h5>
        <StaticImage
          src="../../../images/eversoul/charite/image4.webp"
          alt="Charite"
        />
        <p>
          This applies a 25% attack debuff to your whole team, which can be a
          decent decrease in your damage if you don’t cleanse it or wait for it
          to wear off before using skills.{' '}
        </p>
        <h5>Absorption (the succ)</h5>
        <StaticImage
          src="../../../images/eversoul/charite/image19.webp"
          alt="Charite"
        />
        <p>
          The main focus mechanic of this raid. When using this skill, Charite
          fills her mana bar over time, but if it is used on a character with a
          shield, it drains her mana instead. Absorption will always target the
          character with the highest current HP (not max), so having a specific
          character be the target of this ability can make your life infinitely
          easier. Whether the character shields themselves (Petra, Soonie) or
          shields others (Seeha, Catherine) a shield character is required for
          every team.
        </p>
        <p>
          The skill will stun the character it targets unless the shield is up
          before the absorption starts, so getting the shield up before the
          skill is important. That said, if the shield pops after the attack has
          already started, the mana drain effect will still work, so a late
          shield is better than no shield.
        </p>
        <p>
          Preventing Charite from using her main skill is both better for DPS
          (she becomes untargetable temporarily while flying into the sky to use
          her skill) and survival (it will likely wipe your team later in the
          fight). Shield characters and timings will be discussed further in the
          character section, as each character has different quirks with their
          shields.
        </p>
        <h5>Main skill</h5>
        <StaticImage
          src="../../../images/eversoul/charite/image3.webp"
          alt="Charite"
        />
        <p>If you see this, you messed up.</p>
        <p>
          Charite will use its main skill regardless of rotation, whenever her
          mana bar fills. The mana bar can of course fill via a few methods;
          whenever Charite uses a skill, by taking too much damage from your
          team, and via Absorption. Taking care to watch her mana bar while
          using skills is important, and a good time to unload skills if she’s
          close to full is during the Absorption skill, since whatever mana she
          gains during this time will be removed at the end (assuming she’s
          using Absorption on a shielded character).{' '}
        </p>
        <StaticImage
          src="../../../images/eversoul/charite/image12.webp"
          alt="Charite"
        />
        <h5>Attack debuffs</h5>
        <p>
          Another mechanic of note is the use of attack debuffs. Charite takes
          extra damage while its attack is debuffed, so bringing characters with
          abilities that do this will also help your overall damage.
        </p>
        <StaticImage
          src="../../../images/eversoul/charite/image5.png"
          alt="Charite"
        />
        <SectionHeader title="Useful Characters" />
        <p>
          Teambuilding for Charite has an incredible amount of possible options,
          as long as you make sure to cover all the important mechanics. This
          makes it easy to tailor your teams based on your account’s available
          options and still do great damage! Below are some of the best options
          for each mechanic, which allow you to mix and match to build your best
          teams.
        </p>
        <h5>Shields</h5>
        <p>
          As mentioned before, shields are the most important mechanic for the
          Charite fight. Each of these is a great addition to your teams and
          you’d need at least one of them in each team to do well in this raid.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="soonie" enablePopover />
          <EversoulCharacter mode="icon" slug="yuria" enablePopover />
          <EversoulCharacter mode="icon" slug="naomi" enablePopover />
          <EversoulCharacter mode="icon" slug="petra" enablePopover />
        </div>
        <ul>
          <li>
            Soonie - Soonie’s kit feels like it’s basically made for this raid.
            She has 2 shields, one of which is on her main skill so it can be
            timed easily. She also has the longest attack debuff in the game on
            her main skill, which helps her enable her team’s DPS as an added
            bonus! Since she’s a tank, it’s also quite easy to get her to have
            the highest HP.
          </li>
          <li>
            Yuria - Her teamwide shield is a subskill, but if you have 4pc speed
            keepsake set on her, and don’t use her main skill until after she
            casts her shield on the first rotation, it pretty much lines up for
            the rest of the fight. Plus she’s pretty much the gold standard
            support unit, bringing all kinds of buffs and heals for your team.
          </li>
          <li>
            Naomi - Her main skill shields your entire team, making her very
            easy to use in this raid. She also brings a metric buttload of
            damage, even if she’s not fully invested - at eternal+ she is still
            a wrecking ball! Her main problem tends to be survival.{' '}
          </li>
          <li>
            Petra - Fitting very well into the usual Undead team, Petra’s shield
            needs a bit of manipulation to make work. She works with either a 2
            or 4 piece speed set, or a Rebecca on the team to speed boost her,
            and you have to ensure you don’t interrupt any of her skills with
            her main skill, which will throw off her rotation and prevent her
            shield from being up at the right time. Only use Petra’s main skill
            in between her sub-skills, when she returns to her idle standing
            animation.
          </li>
        </ul>
        <h5>Mana Control</h5>
        <p>
          Keeping Charite from using her skill is imperative to a successful
          raid - these characters are the best at providing mana control plus
          other team benefits!
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="ayame" enablePopover />
          <EversoulCharacter mode="icon" slug="eve" enablePopover />
          <EversoulCharacter mode="icon" slug="haru" enablePopover />
          <EversoulCharacter mode="icon" slug="aira" enablePopover />
          <EversoulCharacter mode="icon" slug="melfice" enablePopover />
        </div>
        <ul>
          <li>
            Ayame - On top of mana control, she also provides an attack
            amplification for your team, and her DPS itself is nothing to scoff
            at.
          </li>
          <li>
            Eve - A huge boon to your team, she will significantly buff your
            carry’s attack and feed them mana that she drains from Charite.{' '}
          </li>
          <li>
            Haru - Has been a raid constant for some time due to her teamwide
            attack buff and ability to reduce the boss’ mana generation.
          </li>
          <li>
            Aira - Similar to Haru, except provides damage boosts through
            defensive debuffs on the enemy while reducing mana generation.{' '}
          </li>
          <li>
            Melfice (+ Bryce) - The dynamic duo works quite well in Charite,
            especially since Melfice obtained the ability to drain mana on her
            main skill when paired with Bryce
          </li>
        </ul>
        <h5>Attack Down</h5>
        <p>
          While not entirely necessary in this raid (as in, the mechanic won’t
          cause your demise if you ignore it), bringing attack down characters
          can raise your DPS by a large margin.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="garnet" enablePopover />
          <EversoulCharacter mode="icon" slug="violette" enablePopover />
          <EversoulCharacter mode="icon" slug="petra" enablePopover />
          <EversoulCharacter mode="icon" slug="soonie" enablePopover />
          <EversoulCharacter mode="icon" slug="ayame" enablePopover />
        </div>
        <ul>
          <li>
            Garnet - Her whole kit is honestly amazing for raids, bringing crit
            damage bonuses and defense reduction, so including her in any of
            your teams is usually a decent boost.{' '}
          </li>
          <li>
            Violette - Should already be on your undead team anyway, since she’s
            the central factor. This is just a very nice bonus in her kit.
          </li>
          <li>
            Petra - The shielder for your undead team, so she should already be
            there. The undead team is stacked quite well as usual.
          </li>
          <li>
            Soonie - As mentioned before, she’s great for his raid due to her
            shields and the uptime on her atk debuff.
          </li>
          <li>
            Ayame - Already bringing mana control and damage boosts for your
            team, she can also decrease the enemy’s attack!
          </li>
        </ul>
        <h5>Main DPS</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="naomi" enablePopover />
          <EversoulCharacter mode="icon" slug="aki" enablePopover />
          <EversoulCharacter mode="icon" slug="lizelotte" enablePopover />
          <EversoulCharacter mode="icon" slug="eileen" enablePopover />
          <EversoulCharacter mode="icon" slug="tasha" enablePopover />
          <EversoulCharacter mode="icon" slug="jade" enablePopover />
        </div>
        <ul>
          <li>
            Naomi - Is essentially the best DPS option in Charite, specifically
            when paired with Jiho support. Getting speed boosts which allow her
            to stack up her atk buff, then unleashing a huge nuke at the end of
            the fight provides serious damage to Charite. Plus, shields!
          </li>
          <li>
            Aki - Huge DPS, Human faction bonus damage, and mana manipulation
            for helping keep Charite from using her main skill. Easily a top
            tier unit here.
          </li>
          <li>
            Lizelotte - Basically the bossing standard, as every raid so far has
            been a single entity, which guarantees her skills to crit. At Origin
            artifact, she also provides mana control. With the addition of Yuria
            and even more human options however, she may be relegated to the
            sub-DPS role, depending on your available options.
          </li>
          <li>
            Eileen - Human faction, check. Mana control, check. Big DPS, also
            check. Eileen functions well as either a sub-DPS or even a main
            carry, depending on your roster.
          </li>
          <li>
            Tasha - With a tailored (melee-centric) team, Tasha can dish out
            serious damage with all the buffs she provides herself and her
            teammates.{' '}
          </li>
          <li>
            Jade - A suprise addition! With the help of Yuria’s faction bonus
            damage boost, Jade can now work as a strong carry for a second
            human-centric team.
          </li>
        </ul>
        <h5>Honourable Mentions (other DPS)</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="linzy" enablePopover />
          <EversoulCharacter mode="icon" slug="lute" enablePopover />
          <EversoulCharacter mode="icon" slug="bryce" enablePopover />
          <EversoulCharacter mode="icon" slug="eve" enablePopover />
          <EversoulCharacter mode="icon" slug="violette" enablePopover />
        </div>
        <ul>
          <li>
            Linzy - A good space filler due to her high DPS and human faction
            bonus. She’s a decent option, assuming you can build her Daze stacks
            quickly and crit often.
          </li>
          <li>
            Lute - Since her release has been a staple main or sub-DPS in
            basically every raid. Since her damage is based on defense rather
            than attack, she can slot in to quite a few teams and provide both
            big damage and teamwide survivability.{' '}
          </li>
          <li>
            Bryce (+ Melfice) - This duo with their spammable ultimates can pack
            a punch in any raid that allows them to spam, but they have to be
            used as a pair, so teambuilding for them provides some challenges.
            On the plus side, Melfice also provides mana control!
          </li>
          <li>
            Eve - It can be challenging to accomplish, but if you can get Eve to
            buff herself (by having the highest attack in the team), she is
            actually able to do very good damage in this raid! Depending on your
            roster, however, she may provide more overall benefits as a support
            to another DPS.{' '}
          </li>
          <li>
            Violette - is the OG bossing character, with her awesome ramp-up
            damage and an easy-to-build undead team that has amazing synergy
            that’s worked on pretty much every boss so far. She’s lost her
            throne in Charite, but is still a viable option if you lack others.
            She even works without much investment, making her very F2P
            friendly.
          </li>
        </ul>
        <h5>Cleanse</h5>
        <p>
          Cleanse isn’t strictly necessary in this fight since the debuff isn’t
          particularly long, and the DPS phase isn’t limited to a specific
          window as it is in Ruthra. That said, most of these characters will
          end up finding a slot in your teams anyway, due to the need for heals
          and buffs.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="yuria" enablePopover />
          <EversoulCharacter mode="icon" slug="prim" enablePopover />
          <EversoulCharacter mode="icon" slug="talia" enablePopover />
          <EversoulCharacter mode="icon" slug="jiho" enablePopover />
          <EversoulCharacter mode="icon" slug="clara" enablePopover />
        </div>
        <SectionHeader title="Team examples" />
        <StaticImage
          src="../../../images/eversoul/charite/team_1.jpg"
          alt="Charite"
        />
        <h5>Jiho Speed Buff</h5>
        <p>
          This team works great, especially in Charite where humans have the
          advantage. Spam Jiho’s ultimate the whole fight to make your team
          extra speedy and build up Naomi’s stacks, then unleash the NaomiNuke
          as your last ultimate of the fight and watch the numbers go up!
        </p>
        <StaticImage
          src="../../../images/eversoul/charite/team_2.jpg"
          alt="Charite"
        />
        <h5>Beast Boost</h5>
        <p>
          This team works great with various carries, though Lize is one of the
          top contenders. This team also has a lot of versatility for swapping
          units: Haru and Aira both work for support and mana control.
        </p>
        <ul>
          <li>
            Tasha DPS: Lize &gt; Tasha, Eve &gt; Lute/Eileen (with the Beast
            treasure)
          </li>
          <li>
            Eve DPS: Eve and Lute in place of Lize and Eve (with Atk necklace)
          </li>
        </ul>
        <StaticImage
          src="../../../images/eversoul/charite/team_3.jpg"
          alt="Charite"
        />
        <h5>Human 2.0</h5>
        <p>
          Using Yuria to boost a second human team works wonders against
          Charite, assuming you have the human units to back it up. Similar
          setups would also work well using Jade and even Dora as replacements
          for Eileen, Cherrie and/or Linzy. Just make sure to watch Charite’s
          mana!
        </p>
        <StaticImage
          src="../../../images/eversoul/charite/team_4.jpg"
          alt="Charite"
        />
        <h5>Twins Ultimate Spamming Party</h5>
        <p>
          If you’re not using Yuria with a second human team for her faction
          boost (since she doesn’t fit into the Jiho team), you can use her here
          to great effect. Between Melfice’s mana drain and Bryce’s guaranteed
          crits, the damage is amazing even without faction bonus.
        </p>
        <StaticImage
          src="../../../images/eversoul/charite/team_5.jpg"
          alt="Charite"
        />
        <h5>The retro</h5>
        <p>
          The Undead team, an old staple for every raid. Easy to build, great
          synergy, and works at low ascensions! Some variations of this team
          exist, as Rebecca may be needed to speed boost Petra for shield
          timing. (This team is basically just a budget option at this point, as
          better options exist, but this still works if you don’t have them).
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesCharite;

export const Head: React.FC = () => (
  <Seo
    title="Charite - raid guide | Eversoul | Prydwen Institute"
    description="Struggling with the Charite Raid? This guide will help you!"
    game="eversoul"
  />
);
